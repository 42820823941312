import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Switch, Route, Redirect } from "react-router-dom";
import { Container, IconButton, Snackbar } from "@material-ui/core";
import MaterialCloseIcon from "@material-ui/icons/Close";

// selectors
import { selectUserData } from "./redux/user/selector";
import { selectWidth } from "./redux/window-size/selector";
//pages imports
import Home from "./pages/home";
import Products from "./pages/products";
import { analytics } from "./googleAnalytics";

// components imports
import Footer from "./components/footer/index.jsx";
import FooterBar from "./components/footer-bar";
import Header from "./components/header";
import HeaderMobile from "./components/header-mobile";

// actions
import { change_width } from "./redux/window-size/actions";
import "./App.css";
import ProductDetails from "./pages/product-details";
import Profile from "./pages/profile";
import Concierge from "./pages/concierge";
import AboutUs from "./pages/about";
import ContactUs from "./pages/contact-us";
import LoginSignup from "./components/login-signup";
import CustomModal from "./components/custom-modal";
import axios from "./utils/axios";
import ScrollTop from "./components/scroll-top";
import AddItem from "./pages/add-item";
import CustomButton from "./components/custom-button";
import { Helmet } from "react-helmet";
import TermsConditions from "./pages/terms-conditions";
import PageNotFound from "./pages/page-not-found";
import DealsPage from "./pages/deals";
import Services from "./pages/services/Services.js";
import NewContactUs from "./pages/NewContactUs/NewContactUs.js";
import Faq from "./pages/faq/index.jsx";
import Buy from "./pages/Buy/Buy.jsx";
import Brands from "./pages/Brands/Brands.jsx";

class App extends React.Component {
  constructor(props) {
    super();
    this.state = {
      openMsg: false,
      vertical: "top",
      horizontal: "center",
      msg: "",
      open: false,
    };
  }
  // interceptor
  handleShowMsg = (newState) => {
    this.setState({ openMsg: true, ...newState });
  };

  handleCloseMsg = () => {
    this.setState({ openMsg: false });
  };

  updateDimensions = () => {
    this.props.changing_width({ width: window.innerWidth });
  };
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }
  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions);
  }
  componentWillMount() {
    const that = this;
    axios.interceptors.request.use(
      function (config) {
        if (
          !that.props.user &&
          config.url.indexOf("AddItemToWishlist") !== -1
        ) {
          that.handleShowMsg({
            vertical: "top",
            horizontal: "center",
            msg: (
              <div className="login-alert">
                You are unauthorized please login
                <CustomButton
                  styles={{ marginTop: "35px", display: "block" }}
                  onClick={() => {
                    this.handleCloseMsg();
                    this.handleOpenLogin();
                  }}
                >
                  Login
                </CustomButton>
              </div>
            ),
          });
          return Promise.reject("login required");
        }
        return config;
      },
      function (error) {
        // Do something with request error
        return Promise.reject(error);
      }
    );
    axios.interceptors.response.use(
      (res) => {
        if (
          res.request.responseURL.indexOf("AddItemToWishlist") !== -1 ||
          res.request.responseURL.indexOf("Assistance") !== -1
        ) {
          if (!res.data.authorized) {
            this.handleShowMsg({
              vertical: "top",
              horizontal: "center",
              msg: (
                <div className="login-alert">
                  You are unauthorized please login
                  <CustomButton
                    styles={{ marginTop: "35px", display: "block" }}
                    onClick={() => {
                      this.handleCloseMsg();
                      this.handleOpenLogin();
                    }}
                  >
                    Login
                  </CustomButton>
                </div>
              ),
            });
            return Promise.reject("un auth");
          } else if (!res.data.success) {
            this.handleShowMsg({
              vertical: "top",
              horizontal: "center",
              msg: (
                <div className="login-alert">
                  {res.data.errors[0].message}
                  {/* <CustomButton
                    styles={{ marginTop: "35px", display: "block" }}
                    onClick={() => {
                      this.handleCloseMsg();
                      this.handleOpenLogin();
                    }}
                  >
                    Login
                  </CustomButton> */}
                </div>
              ),
            });
            return Promise.reject("error happened");
          }
        }

        return res;
      },
      (error) => {
        this.handleShowMsg({
          vertical: "top",
          horizontal: "center",
          msg: (
            <div className="login-alert">
              You are unauthorized please login
              <CustomButton
                styles={{ marginTop: "35px", display: "block" }}
                onClick={() => {
                  this.handleCloseMsg();
                  this.handleOpenLogin();
                }}
              >
                Login
              </CustomButton>
            </div>
          ),
        });
        return Promise.reject(error);
      }
    );
  }

  handleOpenLogin = () => {
    this.setState({ open: true });
  };
  handleCloseLogin = () => {
    this.setState({ open: false });
  };

  render() {
    const { user } = this.props;
    const { width } = this.props;
    const { vertical, horizontal, openMsg } = this.state;
    if (user) axios.defaults.headers.common["Authentication"] = user.token;
    else axios.defaults.headers.common["Authentication"] = null;
    return (
      <>
        <ScrollTop />
        {user ? null : (
          <CustomModal
            open={this.state.open}
            classes="login-modal"
            closeModal={this.handleCloseLogin}
          >
            <LoginSignup handleCloseLogin={this.handleCloseLogin} />
          </CustomModal>
        )}

        <div>
          <Helmet>
            <script
              id="ze-snippet"
              src="https://static.zdassets.com/ekr/snippet.js?key=b589035f-3330-40e9-910e-354241b9ba3c"
            ></script>
          </Helmet>
          {width <= 985 && (
            <HeaderMobile handleOpenLogin={this.handleOpenLogin} />
          )}
          {/* : (
            <Header handleOpenLogin={this.handleOpenLogin} />
          )} */}
          <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={openMsg}
            onClose={this.handleCloseMsg}
            message={this.state.msg}
            key={vertical + horizontal}
            action={
              <React.Fragment>
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={this.handleCloseMsg}
                >
                  <MaterialCloseIcon fontSize="small" />
                </IconButton>
              </React.Fragment>
            }
          />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/login" component={Home} />
            <Route exact path="/deals/:id/details" component={DealsPage} />
            <Route
              path="/products/:type/:location"
              exact
              render={(props) => (
                <Products
                  link="automotive"
                  location={props.match.params.location}
                  type={props.match.params.type}
                />
              )}
            />
            <Route
              path="/products/:searchTerm"
              exact
              render={(props) => (
                <Products
                  link="automotive"
                  searchTerm={props.match.params.searchTerm}
                />
              )}
            />
            <Route
              path="/automotive"
              exact
              render={(props) => (
                <Products link="automotive" type="Automotive" />
              )}
            />
            <Route path="/buy" exact render={(props) => <Buy />} />
            <Route path="/buy/brands" exact render={(props) => <Brands />} />
            <Route
              path="/real-estate"
              exact
              render={(props) => (
                <Products link="real-estate" type="Real Estate" />
              )}
            />
            <Route
              path="/life-style"
              exact
              render={(props) => <Products link="life-syle" type="Lifestyle" />}
            />
            <Route exact path={`/add-item`} component={AddItem} />
            <Route exact path={`/services`} component={Services} />
            <Route exact path={`/faq`} component={Faq} />

            <Route path="/products-details/:id" component={ProductDetails} />

            <Route path="/profile" component={Profile} />
            <Route exact path="/concierge" component={Concierge} />
            <Route
              exact
              path="/get-app"
              render={() => (window.location = "http://onelink.to/tze8xa")}
            />
            <Route exact path="/about-us" component={AboutUs} />
            <Route exact path="/contact-us" component={NewContactUs} />
            <Route exact path="/terms-conditions" component={TermsConditions} />
            <Route exact path="/404-notfound" component={PageNotFound} />
            <Route path="/">
              <Redirect to="/404-notfound" />
            </Route>
          </Switch>

          <Footer />
        </div>
        <FooterBar />
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  user: selectUserData,
  width: selectWidth,
});

const mapDispatchToProps = (dispatch) => ({
  changing_width: (width) => dispatch(change_width(width)),
});
export default connect(mapStateToProps, mapDispatchToProps)(App);
