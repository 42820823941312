import React, { useEffect, useState } from "react";
import { createStructuredSelector } from "reselect";
import "./header.scss";
import { Link, useHistory, useLocation } from "react-router-dom";
import logo from "../../assets/small-logo.png";
import home from "../../assets/home.svg";
import car from "../../assets/header-car.svg";
import watch from "../../assets/header-watch.svg";
import businessman from "../../assets/businessman.svg";
import searchIcon from "../../assets/search.svg";
import arrow from "../../assets/arrow.svg";
import downArrow from "../../assets/down-arrow.svg";
import userIcon from "../../assets/userIcon.svg";
import axios from "../../utils/axios";

import MenuItem from "@material-ui/core/MenuItem";

import { Divider } from "@material-ui/core";
import { selectUserData } from "../../redux/user/selector";
import { connect, useDispatch } from "react-redux";
import CustomDropdown from "../custom-dropdown";
import { user_logout } from "../../redux/user/actions";
import CustomModal from "../custom-modal";
import CustomInput from "../custom-input";
import CustomCard from "../custom-card";
import { FaPhone, FaPhoneAlt } from "react-icons/fa";
import cataloguePDF from "../../assets/catalogue.pdf";
import userPart from "../../assets/download.png";
import AboutUs from "../AboutUs/AboutUs";
import HomeSlider from "../home-slider";
import ProductLinks from "../ProductLinks/ProductLinks";
import connectedLogo from "../../assets/connected-logo.png";

const Header = ({ handleOpenLogin, user, slides }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname === "/login" && !user) handleOpenLogin();
  }, [user, pathname, handleOpenLogin]);

  const anchorRef = React.useRef(null);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);
  const [openSearch, setOpenSearch] = useState(false);
  const [search, setSearch] = useState(undefined);
  const [searchList, setSearchList] = useState([]);
  const handleSearch = (e) => {
    const val = e.target.value;
    setSearch(val);
  };
  const history = useHistory();
  const submitSearch = () => {
    handleCloseSearch();
    history.push("/products/" + search);
  };
  const selectSearch = (res) => {
    handleCloseSearch();
    history.push("/products/" + res);
  };
  const handleCloseSearch = () => {
    setOpenSearch(false);
  };
  const handleOpenSearch = () => {
    setOpenSearch(true);
  };
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      axios
        .post("AutoCompleteSearch", {
          term: search,
          category: null,
        })
        .then((res) => {
          setSearchList(res.data?.result?.value || []);
        });
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [search]);
  return (
    <div className="header-container">
      {/* <CustomModal
        classes="search-modal"
        closeModal={handleCloseSearch}
        open={openSearch}
      >
        <CustomCard classes="search-card">
          <div className="search-card-input">
            <CustomInput
              handleChange={handleSearch}
              value={search}
              label="Search"
            />
            <img alt="search icon" onClick={submitSearch} src={searchIcon} />
          </div>
          {searchList.length ? (
            <ul>
              {searchList.map((ele) => (
                <li onClick={() => selectSearch(ele.document.Title)}>
                  {ele.document.Title}
                </li>
              ))}
            </ul>
          ) : null}
        </CustomCard>
      </CustomModal>*/}
      <header className="header">
        <ProductLinks />
        <Link className="header-link" to="/services">
          Services
        </Link>
        <Link className="header-link" to="/concierge">
          Exclusive Deals
        </Link>
        <AboutUs />
        <Link className="header-link" to="/">
          <img alt="Home Page" src={connectedLogo} />
        </Link>
        {/*        <Link className="header-link" to="/real-estate">
          <img alt="Real Estate" src={home} />
          <p>Real Estate</p>
        </Link>
        <Link className="header-link" to="/automotive">
          <img alt="Automotive" src={car} />
          <p>Automotive</p>
        </Link>
        <Link className="header-link" to="/life-style">
          <img alt="Life Style" src={watch} />
          <p>Life Style</p>
        </Link>
        <Link className="header-link" to="/concierge">
          <img alt="Premium Advertisement" src={businessman} />
          <p>Premium Advertisement</p>
        </Link> */}
        {/* <div className="search-icon">
          <img onClick={handleOpenSearch} alt="search icon" src={searchIcon} />
        </div> */}
        <Link className="header-link" target="_blank" to={cataloguePDF}>
          Monthly Catalogue
        </Link>
        <Link
          className="header-link"
          to="/#getTheApp"
          onClick={(e) => {
            e.preventDefault();
            document
              .getElementById("getTheApp")
              .scrollIntoView({ behavior: "smooth" });
          }}
        >
          Get The App
        </Link>
        <Link className="header-link" to="/contact-us">
          <FaPhoneAlt className="phone" />
        </Link>

        {user ? (
          <div
            className="user-name"
            ref={anchorRef}
            aria-controls={open ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
          >
            <img src={userIcon} alt="user" />{" "}
            <span>{user.name.split(" ")[0]}</span>{" "}
            <img className="header-arrow" src={downArrow} alt="arrow" />
            <CustomDropdown
              anchorRef={anchorRef}
              open={open}
              setOpen={setOpen}
              handleToggle={handleToggle}
              handleClose={handleClose}
              prevOpen={prevOpen}
            >
              <MenuItem onClick={handleClose}>
                <Link to="/add-item">Add Item</Link>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <Link to="/profile/wishlist">My Wishlist</Link>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <Link to="/profile/items">My Items</Link>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <Link to="/profile/info">My Profile</Link>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <Link to="/profile/settings">Profile settings</Link>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setOpen(false);
                  dispatch(user_logout());
                }}
              >
                <Link to="/">Logout</Link>
              </MenuItem>
            </CustomDropdown>
          </div>
        ) : (
          <div className="user-part" onClick={handleOpenLogin}>
            <img class="header-icon" src={userPart} alt="avatar" />
          </div>
        )}
      </header>

      {/*<Divider className="divider" /> */}
    </div>
  );
};
const mapStatetoProps = createStructuredSelector({
  user: selectUserData,
});
export default connect(mapStatetoProps)(Header);
