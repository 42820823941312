import React, { useEffect, useState } from "react";

import "./about.scss";
import PrevPageLinks from "../../components/prev-page-links";
import { Link } from "react-router-dom";
import { Container, Divider, Grid } from "@material-ui/core";
import ServiceCard from "../../components/service-card";
import aboutUsImg from "../../assets/about-us.png";
import axios from "../../utils/axios";
import Header from "../../components/header";
import connectedLogo from "../../assets/connected-logo.png";
import ceo from "../../assets/ceo.jpeg";

const AboutUs = () => {
  const [services, setServices] = useState([]);
  useEffect(() => {
    axios.get("GetServices").then((res) => {
      const ids = Object.keys(res.data.result.data);
      const elements = ids.map((id) => res.data.result.data[id]);
      setServices(elements);
    });
  }, []);
  return (
    <div>
      <Header />
      <Container>
        <div className="about-connected-vip">
          <div className="about-connected-vip__content">
            <div className="about-connected-vip__image">
              <img alt="logo" src={connectedLogo} />
            </div>
            <div className="about-connected-vip__text">
              <h2>About Connected VIP</h2>
              <p>
                Connected© VIP is the first safe E-Commerce private network for
                VIPs, where all clients are thoroughly vetted to prevent
                scammers from lurking inside our network preying on the
                innocents. Providing its VIP clients with the following:
              </p>
              <ol>
                <li>
                  First-of-its-kind premium personalized experience in an
                  E-Commerce platform, where users feel recognized and
                  appreciated. Clients are always reminded of their prominence
                  by providing specialized support for each client individually
                  through their journey.
                </li>
                <li>
                  Providing both buyers and sellers with appropriate anonymity
                  which is exceedingly desired in the case of first-class
                  clients. Provide high-profile establishments with the means to
                  advertise their premium products on our platform giving them
                  access to the top-tier they’re seeking.
                </li>
                <li>
                  Provide high-profile establishments with the means to
                  advertise their premium products on our platform giving them
                  access to the top-tier they’re seeking.
                </li>
                <li>
                  Provide users with appropriate tools to seek particular
                  products with top-of-the-line smart search capabilities.
                </li>
                <li>
                  Allow users with the need to sell their items to boost their
                  products on our platform effectively giving them a better
                  chance at achieving their desired goals.
                </li>
                <li>
                  Serve our customers to the best of our abilities through
                  dedicated professional assistance in the form of concierge
                  services presented in but not limited to (consultancy,
                  reservation of Hotels, restaurants, events conference, life
                  insurance, etc.) with a 24/7 availability.
                </li>
              </ol>
            </div>
          </div>
        </div>
        <Divider className="divider" />
        <div className="ceo-profile">
          <div className="ceo-profile__image">
            <img src={ceo} alt="Mohamed El-Kadi" />
            <h3 style={{ textAlign: "center" }}>Mohamed El-Kadi</h3>
            <p className="ceo-profile__position">CEO & Founder</p>
          </div>
          <div className="ceo-profile__text">
            <h2>THE MAN BEHIND IT ALL</h2>
            <p>
              After 13 years of Success working for BMW Egypt starting from
              Sales Executive reaching Sales director, Board Member and CEO,
              creating the strong presence of BMW Egypt over the last ten years
              by being ranked top sales man over Egypt and the middle East
              building the biggest network of Vips across the region. El-Kadi
              now left BMW with a strong vision in mind; Creating the most
              innovative utility to forever change the luxury market. A strong
              believer in ideas and innovation, backed up with a unique deep
              understanding of the luxury market.
            </p>
          </div>
        </div>
      </Container>
    </div>
  );
};
export default AboutUs;

// <div className="about-us">
//       <PrevPageLinks>
//         <Link className="prev" to="/">
//           Home
//         </Link>{" "}
//         /{" "}
//         <Link className="current" to="/about-us">
//           About us
//         </Link>
//       </PrevPageLinks>
//       <h2>ABOUT US</h2>
//       <Grid container spacing={3}>
//         <Grid className="who-we-are" item lg={6} md={6} sm={12}>
//           <div>
//             <h3 style={{ marginTop: "0px" }}>WHO WE ARE</h3>
//             <p>
//               Connected®️ VIP Assistant is the first safe E-Commerce private
//               network for VIPs, where all clients are thoroughly vetted to
//               prevent scammers from lurking inside our network preying on the
//               innocents. Providing its VIP clients with the following:
//             </p>
//             <p>
//               1. First-of-its-kind premium personalized experience in an
//               E-Commerce platform, where users feel recognized and appreciated.
//               Clients are always reminded of their prominence by providing
//               specialized support for each client individually through their
//               journey.
//               <br />
//               2. Providing both buyers and sellers with appropriate anonymity
//               which is exceedingly desired in the case of first-class clients
//               Provide high-profile establishments with the means to advertise
//               their premium products on our platform giving them access to the
//               top-tier they’re seeking.
//               <br />
//               3. Provide high-profile establishments with the means to advertise
//               their premium products on our platform giving them access to the
//               top-tier they’re seeking.
//               <br />
//               4. Provide users with appropriate tools to seek particular
//               products with top-of-the-line smart search capabilities.
//               <br />
//               5. Allow users with the need to sell their items to boost their
//               products on our platform effectively giving them a better chance
//               at achieving their desired goals.
//               <br />
//               6. Serve our customers to the best of our abilities through
//               dedicated professional assistance in the form of concierge
//               services presented in but not limited to (consultancy, reservation
//               of Hotels, restaurants, events conference, life insurance, etc.)
//               with a 24/7 availability.
//             </p>
//           </div>
//         </Grid>
//         <Grid item lg={6} md={6} sm={12}>
//           <div>
//             <img
//               style={{ maxWidth: "100%" }}
//               src={aboutUsImg}
//               alt="represtive car"
//             />
//           </div>
//         </Grid>
//       </Grid>
//       <div>
//         <h2 style={{ textAlign: "center" }}>SERVICES</h2>
//         <Grid container spacing={3}>
//           {services.map((ele) => (
//             <Grid item lg={4} md={4} sm={12}>
//               <ServiceCard
//                 imgUrl={process.env.REACT_APP_API_IMAGE_URL + ele.icon}
//                 title={ele.name}
//               />
//             </Grid>
//           ))}
//         </Grid>
//       </div>
//     </div>
