import React from "react";
import { Link } from "react-router-dom";
import "./AboutUs.scss";

const AboutUs = () => {
  return (
    <div className="about-us-container">
      <div className="about-us">
        <p>About Us</p>
        <div className="about-us-dropdown">
          <a href="/about-us">Who is connected?</a>
          <a href="/contact-us">Contact Us</a>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
