import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

import logo from "../../assets/connected-logo.png";
import menu from "../../assets/menu.svg";

import "./header-mobile.scss";
import HeaderMenu from "../header-menu";
import CustomModal from "../custom-modal";
const HeaderMobile = ({ handleOpenLogin }) => {
  const [open, setOpen] = React.useState(false);
  const { pathname } = useLocation();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (pathname === "/login") handleOpen();
  }, [pathname]);

  return (
    <header className="header-mobile">
      <Link className="header-link" to="/">
        <img alt="Home Page" src={logo} />
        Home Page
      </Link>
      <div className="menu-icon">
        <img alt="menu icon" onClick={handleOpen} src={menu} />
      </div>

      <CustomModal open={open}>
        <HeaderMenu
          handleClose={handleClose}
          handleOpenLogin={handleOpenLogin}
        />
      </CustomModal>
    </header>
  );
};

export default HeaderMobile;
