import React from "react";
import { Link } from "react-router-dom";
import "../AboutUs/AboutUs.scss";

const ProductLinks = () => {
  return (
    <div className="about-us-container">
      <div className="about-us">
        <p>Products</p>
        <div className="about-us-dropdown">
          <a href="/automotive">Automotive</a>
          <a href="/life-style">Lifestyle</a>
          <a href="/real-estate">Realestate</a>
        </div>
      </div>
    </div>
  );
};

export default ProductLinks;
