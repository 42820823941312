import React from "react";
import "./Buy.scss";
import { Container } from "@material-ui/core";
import Header from "../../components/header/index";
import Home from "../../assets/home.svg";
import Watch from "../../assets/watch.svg";
import { IoHomeOutline } from "react-icons/io5";
import { PiWatchLight } from "react-icons/pi";
import { FaCar } from "react-icons/fa";
import { Link } from "react-router-dom";

const categories = [
  {
    name: "Automotive",
    icon: <FaCar className="category-icon" />,
    link: "/buy/brands",
  },
  {
    name: "Lifestyle",
    icon: <PiWatchLight className="category-icon" />,
    link: "/buy/brands",
  },
  {
    name: "Real Estate",
    icon: <IoHomeOutline className="category-icon" />,
    link: "/buy/brands",
  },
];

const Buy = () => {
  return (
    <div>
      <Header />
      <Container>
        <div className="categories-grid">
          <h2>Select Category</h2>
          <div className="grid-container">
            {categories.map((category, index) => (
              <Link to={category.link} key={index} className="category-card">
                {category.icon}
                <p>{category.name}</p>
              </Link>
            ))}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Buy;
