import React, { useState } from "react";
import CustomButton from "../custom-button";
import CustomInput from "../custom-input";
import CustomTextarea from "../custom-textarea";
import axios from "../../utils/axios";

import { CircularProgress } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import "./contact-form.scss";
import ShowNotification from "../notification";
import { Link } from "react-router-dom";
import appStore from "../../assets/app-store.svg";
import playStore from "../../assets/play-store.svg";

const ContactFrom = () => {
  const [state, setState] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [error, setError] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    const { value, name } = e.target;
    setState((state) => ({ ...state, [name]: value }));
  };

  // notification
  const [open, setOpen] = useState(false);
  const handleCloseMsg = () => {
    setOpen(false);
  };
  const handleOpenMsg = () => {
    setOpen(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = {};
    let err = false;
    if (!state.name.trim().length) {
      errors.name = "Name is required";
      err = true;
    } else {
      errors.name = "";
    }
    if (!state.email.trim().length) {
      errors.email = "Email is required";
      err = true;
    } else {
      errors.email = "";
    }
    if (!state.message.trim().length) {
      errors.message = "Message is required";
      err = true;
    } else {
      errors.message = "";
    }
    if (err) {
      setError(errors);
      return;
    } else {
      setError({});
    }
    setIsLoading(true);
    axios
      .post("contact", {
        email: state.email,
        name: state.name,
        message: state.message,
      })
      .then((res) => {
        handleOpenMsg();
        setIsLoading(false);
      });
  };

  return (
    <div>
      <p style={{ textAlign: "center", margin: "1.5rem" }}>
        Connected App is now available on App Store & Google play. Get it now.
      </p>
      <div class="get-app-container">
        <a
          class="get-app"
          target="_blank"
          href="https://apps.apple.com/us/app/connected-vip-app/id1482090673"
        >
          <img src={appStore} alt="app store" />
        </a>
        <a
          class="get-app"
          target="_blank"
          href="https://play.google.com/store/apps/details?id=com.connected.app&pli=1"
        >
          <img src={playStore} alt="play store" />
        </a>
      </div>
    </div>
  );
};

export default ContactFrom;

{
  /*<form autoComplete="off" onSubmit={handleSubmit}>
      <ShowNotification
        open={open}
        msg={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CheckCircleIcon size={22} className="success-icon" /> Email sent
            succefully
          </div>
        }
        handleCloseMsg={handleCloseMsg}
        handleOpenMsg={handleOpenMsg}
      />
      <div className="form-input-container">
        <span className="error-msg">{error.name}</span>
        <CustomInput
          label="Name"
          value={state.name}
          type="text"
          name="name"
          handleChange={handleChange}
        />
        <span className="error-msg">{error.email}</span>
        <CustomInput
          label="Email"
          value={state.email}
          type="email"
          name="email"
          handleChange={handleChange}
        />
        <span className="error-msg">{error.message}</span>
        <CustomTextarea
          label="Message"
          value={state.message}
          name="message"
          handleChange={handleChange}
        />
      </div>

      <CustomButton
        type="submit"
        styles={{
          backgroundColor: "#7A7171",
          width: "96px",
          height: "32px",
          float: "right",
        }}
        disable={isLoading}
      >
        Send {isLoading && <CircularProgress size={10} />}
      </CustomButton>
    </form> */
}
