import React from "react";
import "./Brands.scss";
import Header from "../../components/header";
import { Container } from "@material-ui/core";
import bmw from "../../assets/bmw.png";
import rolex from "../../assets/rolex.jpeg";
import b from "../../assets/b.jpeg";
import ap from "../../assets/ap.svg";
import richard from "../../assets/richard.svg";
import emmar from "../../assets/emmar.jpg";
import horse from "../../assets/horse.jpg";
import sodic from "../../assets/sodic.jpg";
import s from "../../assets/s.jpg";
import dolce from "../../assets/dolce.jpg";

const brands = [
  { name: "BMW", icon: bmw },
  { name: "Rolex", icon: rolex },
  { name: "Mercedes Benz", icon: b },
  { name: "Audemars Piguet", icon: ap },
  { name: "Richard Mille", icon: richard },
  { name: "Emaar Misr", icon: emmar },
  { name: "Ferrari", icon: horse },
  { name: "SODIC", icon: sodic },
  { name: "Porsche", icon: s },
  { name: "Dolce & Gabbana", icon: dolce },
];

const Brands = () => {
  return (
    <div>
      <Header />

      <Container>
        <div className="brands-grid">
          <h2>Select Brand</h2>
          <div className="grid-container">
            {brands.map((brand, index) => (
              <div key={index} className="brand-card">
                <img src={brand.icon} alt={brand.name} className="brand-icon" />
                <p>{brand.name}</p>
              </div>
            ))}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Brands;
