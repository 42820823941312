import React from "react";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";

import "./home-advanced-search.scss";
import CustomButton from "../custom-button";
import CustomSelect from "../custom-select";
import { Colors } from "../../constants";
import ChatIcon from "../chat-icon";
import { useHistory } from "react-router";
import CustomInput from "../custom-input";
import { Widgets } from "@material-ui/icons";
import { Link } from "react-router-dom";
const HomeAdvancedSearch = ({ categories, locations }) => {
  const [category, setCategory] = React.useState(null);
  const [location, setLocation] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [error2, setError2] = React.useState(null);
  const history = useHistory();
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!category) {
      setError("* Category required");
      return;
    } else setError(null);
    // if (!location) {
    //   setError2("* Location required");
    //   return;
    // } else setError2(null);
    history.push(`/products/${category}/${location ? location : "all"}`);
  };
  const handleCategory = (e) => {
    if (e.target.value) setError(null);
    setCategory(e.target.value);
  };
  const handleLocation = (e) => {
    setLocation(e.target.value);
  };
  return (
    <div className="home-advanced-search">
      <form onSubmit={handleSubmit} className="home-form">
        <div className="custom_search_form">
          {/*<Grid className="hidden-mobile" item xs={false} lg={2}></Grid> */}
          <h2 className="slide_header">
            Indulge in luxury <br />
            with a click
          </h2>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <FormControl variant="outlined" className="category">
              <CustomSelect
                options={categories}
                label="Category"
                value={category}
                name="category"
                handleChange={handleCategory}
              />
              {/* {error && <span className="error-msg">{error}</span>} */}
            </FormControl>
            <FormControl variant="outlined" className="search">
              <CustomInput
                type="text"
                className="luxury-search__input"
                placeholder="Search"
              />
            </FormControl>
          </div>

          <div
            style={{
              marginTop: "1rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
              width: "100%",
              fontSize: "20px",
            }}
          >
            <CustomButton
              type="submit"
              styles={{
                color: "#FFFFFF",
                height: "48px",
                width: "140px",
              }}
            >
              <Link style={{ color: "white" }} to="/buy">
                Buy
              </Link>
            </CustomButton>

            <CustomButton
              styles={{
                color: "#FFFFFF",
                height: "48px",
                backgroundColor: "#333",
                width: "140px",
              }}
            >
              Sell
            </CustomButton>
          </div>

          {/*<Grid item xs={12} md={2}>
            <FormControl
              style={{
                minWidth: "100%",
                color: Colors.secondary,
                alignItems: "flex-end",
              }}
            >
              <ChatIcon styles={{ width: "46px", height: "46px" }} />
            </FormControl>
          </Grid> */}
        </div>
      </form>
    </div>
  );
};
export default HomeAdvancedSearch;

// <Grid item xs={6} lg={2} md={2}>
//             {/*<FormControl
//               variant="outlined"
//               style={{
//                 minWidth: "100%",
//                 color: Colors.secondary,
//                 height: "48px",
//               }}
//             >
//               <CustomSelect
//                 options={locations}
//                 label="Location"
//                 value={location}
//                 name="location"
//                 handleChange={handleLocation}
//               />
//               {error2 && <span className="error-msg">{error2}</span>}
//             </FormControl>*/}
//           </Grid>
//           <Grid item xs={12} lg={2} md={2}>
//             <FormControl
//               style={{
//                 minWidth: "100%",
//                 color: Colors.secondary,
//               }}
//             >
//               <CustomButton
//                 type="submit"
//                 styles={{ color: "#FFFFFF", height: "48px" }}
//               >
//                 Quick Search
//               </CustomButton>
//             </FormControl>
//           </Grid>
//           <Grid item xs={12} lg={2} md={2}>
//             <FormControl
//               style={{
//                 minWidth: "100%",
//                 color: Colors.secondary,
//                 alignItems: "flex-end",
//               }}
//             >
//               <ChatIcon styles={{ width: "46px", height: "46px" }} />
//             </FormControl>
//           </Grid>
